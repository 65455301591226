import _ from 'lodash';
import purify from 'dompurify';

import moment from 'moment';
import {defineMessages, FormattedMessage} from 'react-intl';

import LocalizedModal from '../../../components/Common/LocalizedModal/LocalizedModal';

import {
  ACCESS_CODE,
  CONFERENCE_BRIDGE,
  CONFERENCE_BRIDGE_CHECKED,
  EMAIL_MESSAGE_FIELDS,
  EMAIL_SURVEY_FIELDS,
  MAX_CHAR_COUNT,
  MESSAGE_MODALITIES,
  PHONE_NUMBER,
  REQUIRE_STATUS_CONFIRMATION,
  REQUIRE_STATUS_CONFIRMATION_CHECKED,
  RESPONSE_OPTIONS
} from '../../../constants/communicator';
import {COMMUNICATION_KEYS} from '../../../constants/communications';
import {getAbbreviatedSortDirection} from '../admins/utils';

const messages = defineMessages({
  confirmDiscard: {
    id: 'Redux.communicator.confirmDiscard',
    defaultMessage: 'Discarding Message'
  },
  confirmDiscardContent: {
    id: 'Redux.communicator.confirmDiscardContent',
    defaultMessage: 'Are you sure you want to discard your message?'
  },
});

export const Z_INDEX_DRAWER = 1005;
export const Z_INDEX_CONFIRM_MODAL = Z_INDEX_DRAWER + 1;

export const parseMessageTemplates = (messageTemplates) => {
  if (!_.isEmpty(messageTemplates)) {
    const parsed = messageTemplates.map(template => {
      try {
        // Template content entered via template-ms uses single quotes for each key/value pair (for ease of input) -
        // we need to replace those single quotes with double quotes for proper JSON format.
        // We do not want to replace embedded single or double quotes in the content, after replacing all single quotes with double quotes -
        // - we will put the embedded single quote, \\', back
        // - we will put the embedded double quote, \\\\', back
        const updatedTemplateContent = template.content.replace(/'/g, '"').replace(/([^\\])\\"/g, '$1\'').replace(/\\\\"/g, '\\"');
        const parsedContent = JSON.parse(updatedTemplateContent);
        template = {
          ...template,
          content: parsedContent
        };

        return template;
        //return parsedContent?.name && parsedContent?.subject && parsedContent?.richText ? template : null
      }
      catch {
        return null;
      }
    });

    return parsed.filter(x => x);
  }
}

export function formatMessageForApi(formFields) {
  const isCombined = formFields[EMAIL_MESSAGE_FIELDS.formats][EMAIL_MESSAGE_FIELDS.combined];

  if(isCombined || !_.has(formFields, [EMAIL_MESSAGE_FIELDS.formats, EMAIL_MESSAGE_FIELDS.combined])) {
    formFields = addMessageBody(formFields);
  }

  return omitUnnecessaryFields(addSurveyIndices(addConferenceBridge(addSubject(formFields))));
}

export function formatMessageForUpdateApi(formFields) {
  const {templateName, ...rest} = formFields;
  return {templateName, messageConfig: formatMessageForApi(rest)};
}

export function stripHtmlTags(htmlString) {
  return purify.sanitize(htmlString, {ALLOWED_TAGS: []});
}

export function sanitizeHtmlPreservingFormatting(htmlString) {
  return purify.sanitize(htmlString, {
    ALLOWED_TAGS: ['b', 'strong', 'i', 'em', 'u', 'ul', 'ol', 'li', 'p', 'br', 'span', 'div', 'a'],
    ALLOWED_ATTR: ['href', 'target', 'rel'],
    FORBID_ATTR: ['style'],
  });
}

/**
 * For use in COMBINED mode, Get the Max Character limit based on the selected modalities.
 *
 * The character limit in combined mode is the smallest limit of all the selected modalities.
 * For example, if SMS (limit: 277) and PUSH (limit: 3500) are selected,
 * the character limit will be the smallest of the two (which is the SMS limit of 277)
 */
export function getCombinedCharacterLimit(modalities = []) {
  return modalities.map(modality => MAX_CHAR_COUNT[modality]).sort((a,b) => a-b)[0];
}

/**
 * For use in combined mode,
 * This takes the text entered into formFields.messageBody and duplicates it into each modality which is selected.
 * HTML tags are removed for all non-email modalities
 * @param formFields
 */
function addMessageBody(formFields) {
  const selectedModalities = formFields[EMAIL_MESSAGE_FIELDS.messageModalities] || [MESSAGE_MODALITIES.EMAIL_FORMAT];

  selectedModalities.forEach(selectedModality => {
    let messageBody = formFields[EMAIL_MESSAGE_FIELDS.messageBody];

    if (selectedModality !== MESSAGE_MODALITIES.EMAIL_FORMAT) {
      //strips out html tag formatting for SMS, PUSH, and TTS
      messageBody = stripHtmlTags(messageBody);
    }

    _.set(formFields, [EMAIL_MESSAGE_FIELDS.formats, selectedModality, EMAIL_MESSAGE_FIELDS.messageBody], messageBody);
  });

  return formFields;
}

function omitUnnecessaryFields(formFields) {
  const survey = formFields[EMAIL_MESSAGE_FIELDS.survey];
  const conferenceBridge = formFields[CONFERENCE_BRIDGE];
  const isSurveyEmpty = _.isEmpty(survey) || _.isEmpty(survey[EMAIL_SURVEY_FIELDS.QUESTIONS]);
  const isConferenceBridgeEmpty = _.isEmpty(conferenceBridge) || _.isEmpty(conferenceBridge[PHONE_NUMBER]);

  if (formFields[EMAIL_MESSAGE_FIELDS.okResponse] && formFields[EMAIL_MESSAGE_FIELDS.needHelpResponse]) {
    formFields[REQUIRE_STATUS_CONFIRMATION][RESPONSE_OPTIONS][0].text = formFields[EMAIL_MESSAGE_FIELDS.okResponse];
    formFields[REQUIRE_STATUS_CONFIRMATION][RESPONSE_OPTIONS][1].text = formFields[EMAIL_MESSAGE_FIELDS.needHelpResponse];
  }

  const omittedFields = [
    EMAIL_MESSAGE_FIELDS.messageModalities,
    PHONE_NUMBER,
    EMAIL_MESSAGE_FIELDS.messageTemplate,
    EMAIL_MESSAGE_FIELDS.subject,
    EMAIL_MESSAGE_FIELDS.messageBody,
    EMAIL_MESSAGE_FIELDS.okResponse,
    EMAIL_MESSAGE_FIELDS.needHelpResponse,
    REQUIRE_STATUS_CONFIRMATION_CHECKED,//used only for local form state tracking
    CONFERENCE_BRIDGE_CHECKED,//used only for local form state tracking
    ...(isSurveyEmpty ? [EMAIL_MESSAGE_FIELDS.survey]: []), // omit empty survey object to avoid backend 422s
    ...(isConferenceBridgeEmpty ? [CONFERENCE_BRIDGE]: []) // omit empty conference bridge object to avoid backend 422s
  ];

  const payload = _.omit(formFields, omittedFields);

  const surveyHasEmptyResponse = payload[EMAIL_MESSAGE_FIELDS.survey]?.questions?.find(item => item?.responseOptions?.length === 0)
  if (surveyHasEmptyResponse) {
    payload[EMAIL_MESSAGE_FIELDS.survey]?.questions.forEach(item => {
      if (item?.responseOptions?.length === 0) {
        item.responseOptions = undefined
      }
    })
  }

  return payload
}

export function addSurveyIndices(formFields) {
  const questions = formFields[EMAIL_MESSAGE_FIELDS.survey]?.[EMAIL_SURVEY_FIELDS.QUESTIONS];
  questions?.forEach((question, index) => {
    const questionNumber = index + 1;
    question[EMAIL_SURVEY_FIELDS.NUMBER] = questionNumber;

    const responses = question[EMAIL_SURVEY_FIELDS.RESPONSE_OPTIONS];
    if (!_.isEmpty(responses)) {
      responses.forEach((response, index) => {
        const responseNumber = index + 1;
        response[EMAIL_SURVEY_FIELDS.NUMBER] = responseNumber;
      });
    }
  });

  return formFields;
}

function addConferenceBridge(formFields) {
  const selectedModalities = formFields[EMAIL_MESSAGE_FIELDS.messageModalities];
  const conferenceBridge = formFields[CONFERENCE_BRIDGE];

  selectedModalities?.forEach(selectedModality => {
    let messageBody = formFields[EMAIL_MESSAGE_FIELDS.formats][selectedModality][EMAIL_MESSAGE_FIELDS.messageBody];

    const conferenceBridgeText = getConferenceBridgeText(selectedModality, conferenceBridge);

    if(_.isString(conferenceBridgeText)){
      messageBody = `${messageBody}${conferenceBridgeText}`;
      _.set(formFields, [EMAIL_MESSAGE_FIELDS.formats, selectedModality, EMAIL_MESSAGE_FIELDS.messageBody], messageBody);
    }

  });

  return formFields;
}

/**
 * @param modality - the modality
 * @param conferenceBridge {{phoneNumber: string, accessCode?: string}} -
 */
export function getConferenceBridgeText(modality, conferenceBridge) {
  let phoneNumber = conferenceBridge?.[PHONE_NUMBER];
  let accessCode = conferenceBridge?.[ACCESS_CODE];//This access code is optional, so we need to account for that when we build the text

  const isSMS = modality === MESSAGE_MODALITIES.SMS_FORMAT;
  const isTTS = modality === MESSAGE_MODALITIES.TTS_FORMAT;

  if (_.isString(modality) && _.isString(phoneNumber)) {
    if (isSMS) {
      let dialInText = ` Dial-in ${phoneNumber}`;
      if(_.isString(accessCode)) {
        dialInText += `,${accessCode}`
      }

      return dialInText;
    }
    else {
      //all other modalities

      if(isTTS) {
        //We add a space between each number in the phone number and access code so that the Text-To-Speech system will pronounce them correctly.
        phoneNumber = phoneNumber.split('').join(' ');
        accessCode = accessCode?.split('').join(' ');
      }

      let dialInText = ` Use the following number to access the conference bridge: ${phoneNumber}`
      if(_.isString(accessCode)) {
        dialInText += `, Access code: ${accessCode}`;
      }

      return dialInText;
    }
  }
}

/**
 * Takes the subject text formFields[EMAIL_MESSAGE_FIELDS.subject]
 * and duplicates it into emailFormat and pushFormat (if these formats are selected)
 * @param formFields
 */
function addSubject(formFields) {
  const emailFormat = formFields[EMAIL_MESSAGE_FIELDS.formats]?.[MESSAGE_MODALITIES.EMAIL_FORMAT];
  if (!_.isNil(emailFormat?.[EMAIL_MESSAGE_FIELDS.messageBody])) {
    //email modality is selected, add the subject
    emailFormat[EMAIL_MESSAGE_FIELDS.subject] = formFields[EMAIL_MESSAGE_FIELDS.subject];
  }

  const pushFormat = formFields[EMAIL_MESSAGE_FIELDS.formats]?.[MESSAGE_MODALITIES.PUSH_FORMAT];
  if (!_.isEmpty(pushFormat?.[EMAIL_MESSAGE_FIELDS.messageBody])) {
    //push modality is selected, add the subject
    pushFormat[EMAIL_MESSAGE_FIELDS.subject] = formFields[EMAIL_MESSAGE_FIELDS.subject];
  }
  return formFields;
}

function getSortableField(field) {
  if (field.includes(COMMUNICATION_KEYS.messageSentAt)) {
    field = COMMUNICATION_KEYS.sentAt
  }
  if (field.includes(COMMUNICATION_KEYS.responseOption)) {
    field = COMMUNICATION_KEYS.surveyResponse
  }

  return field;
}

/**
 *
 * @param {string} field - The field that will be sorted
 * @param {string} order - The order: one of constants.ASCEND or constants.DESCEND
 * @return {string} the field that can be specified in the sort API (e.g. 'emailAddress,asc')
 */
export function getSortString(field, order) {
  return `${getSortableField(field)},${getAbbreviatedSortDirection(order)}`;
}

function setTimeToStartOfDay(momentUtcDatetimeString) {
  return moment.utc(momentUtcDatetimeString).utcOffset(0, true).utc().startOf('day').toISOString();
}

function setTimeToEndOfDay(momentUtcDatetimeString) {
  return moment.utc(momentUtcDatetimeString).utcOffset(0, true).utc().endOf('day').toISOString();
}

export function formatFilter({
  organizationId,
  dateRange,
  authorPersonIds,
  recipientPersonIds,
  messageTypes,
  type,
  messageContent,
  channelTypes,
  clientId,
  correlationId,
  messageType,
  recipientStatuses
}) {

  const from = Array.isArray(dateRange) ? setTimeToStartOfDay(dateRange[0]) : undefined;
  const to = Array.isArray(dateRange) ? setTimeToEndOfDay(dateRange[1]) : undefined;

  return {
    organizationId,
    dateRange: {from, to},
    authorPersonIds,
    recipientPersonIds,
    communicationType: type,
    messageTypes,
    messageContent,
    channelTypes,
    clientId,
    correlationId,
    messageType,
    recipientStatuses
  };
}

export function showConfirmDiscardMessage({onOk, onCancel}) {
  LocalizedModal.confirm({
    maskClosable: true,
    zIndex: Z_INDEX_CONFIRM_MODAL,
    title: <FormattedMessage {...messages.confirmDiscard} />,
    content: <FormattedMessage {...messages.confirmDiscardContent} />,
    onOk: () => {
      onOk?.()
    },
    onCancel: () => {
      onCancel?.()
    }
  });
}

function combineMessage(formFields) {
  const selectedModalities = formFields[EMAIL_MESSAGE_FIELDS.messageModalities] || [MESSAGE_MODALITIES.EMAIL_FORMAT];
  let messageArray = [];

  selectedModalities.forEach(selectedModality => {
    let messageBody = formFields[EMAIL_MESSAGE_FIELDS.formats][selectedModality][EMAIL_MESSAGE_FIELDS.messageBody];
    messageBody = stripHtmlTags(messageBody);

    messageArray = [...messageArray, messageBody]
  })

  return messageArray
}


export function getLongestMessage(formFields) {
  const isSeparated = formFields[EMAIL_MESSAGE_FIELDS.formats][EMAIL_MESSAGE_FIELDS.combined] === false;

  if (isSeparated) {
    const messageArray = combineMessage(formFields);
    // return the longest message
    return messageArray.reduce((longest, current) => longest.length > current.length ? longest : current);
  } else {
    return stripHtmlTags(formFields[EMAIL_MESSAGE_FIELDS.messageBody]);
  }
}
